<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Schedule Change Details: {{ stuName }} - {{ stuBannerId }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn to="/student/schedule-change/admin" text>Back to List</v-btn>
      </v-toolbar>
    </v-card>
    <v-row>
      <v-col cols="12" md="10" offset-md="1" lg="6" offset-lg="0">
        <v-subheader>Requested Schedule Changes</v-subheader>
        <v-subheader style="display:inline-block">Click on the <v-icon color="success">fal fa-check-circle</v-icon> icon next to a schedule change request to approve that request.</v-subheader>
        <div v-if="isRecords">
          <v-btn small @click="showAllChanges = !showAllChanges">{{ showAllChanges ? 'Hide' : 'Show' }} Processed Changes</v-btn>
        </div>
        <v-list elevation="2">
          <course-entry
            v-for="(item, index) in changes"
            :key="item.crn"
            :item="item"
            :changed="true"
            :changeable="false"
            :admin="true"
            :approver="item.approval.pidm === user.pidm"
            :show-all="showAllChanges"
            :require-grade="requireGrade"
            @approve="approveCourse(item, index)"
          ></course-entry>
        </v-list>
        <v-dialog v-model="viewSchedule" width="800">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" style="margin:1em 1em 1em 0">View full schedule</v-btn>
          </template>
          <v-card>
            <v-sheet height="600">
              <v-calendar type="week" :events="classWeek" first-interval="7" :event-color="(event) => event.removed ? 'red' : (event.original ? 'blue' : 'green')"></v-calendar>
            </v-sheet>
            <v-card-actions>
              <v-btn @click="viewSchedule = false" text>Close Schedule</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-list style="margin-bottom:1em;">
          <v-list-item v-for="(advisor, index) in advisors" :key="advisor.pidm">
            <v-list-item-content>
              <v-list-item-title>Advisor: {{ advisor.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ advisor.date ? 'Approved: ' + advisor.date : 'Not Approved' }}</v-list-item-subtitle>
              <v-list-item-subtitle>
                <a :href="'mailto:' + advisor.email" target="_blank">{{ advisor.email }}</a>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="isRecords">
              <v-dialog v-model="advisorChangeDialog" width="600">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" outlined>Change Advisor</v-btn>
                </template>
                <v-card>
                  <v-card-title>Select New Advisor</v-card-title>
                  <v-card-text>
                    <user-search v-model="newAdvisor" search-label="GreatScots Faculty Search" :person-filter="[ 'Faculty' ]"></user-search>
                    <v-checkbox v-if="advisor.date && advisor.date !== ''" v-model="removeApproval" label="Clear advisor approval so the new advisor should approve?"></v-checkbox>
                    <v-checkbox v-model="emailNewAdvisor" label="Email new advisor to approve this schedule change"></v-checkbox>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text @click="advisorChangeDialog = false">Cancel</v-btn>
                    <v-btn :disabled="!('value' in newAdvisor)" color="success" text @click="changeAdvisor(index, advisor.pidm)">Set New Advisor</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list-item-action>
            <v-list-item-action v-else-if="advisor.date">
              <v-icon color="success">fal fa-check-circle</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card-actions v-if="status === 'pending' || status === 'approved'" class="pl-0">
          <template v-if="isRecords">
            <v-tooltip v-if="!advisorApproved || !instructorsApproved" top>
              <template v-slot:activator="{ on }">
                <v-btn @click="markProcessed()" v-on="on" outlined>
                  Mark as Processed
                  <v-icon color="info" right>fas fa-info-circle</v-icon>
                </v-btn>
              </template>
              <span>Doing this will clear out all pending acknowlegements from the student's advisor and/or instructors.</span>
            </v-tooltip>
            <v-btn v-else @click="markProcessed()">
              Mark as Processed
              <v-icon color="success" right>fas fa-check-circle</v-icon>
            </v-btn>
          </template>
          <v-btn v-else-if="!isRecords && isAdvisor && !advisorApproved" @click="advisorApprove()">Approve Schedule Changes</v-btn>
          <v-btn v-else-if="!isRecords && !isAdvisor && !instructorsApproved" @click="instructorApproveAll()">
            <v-icon left color="success">fal fa-check-circle</v-icon>
            Approve Schedule Changes
          </v-btn>
          <v-spacer></v-spacer>
          <v-dialog v-if="isRecords || isAdvisor" v-model="returnDialog" width="400">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="info">Return to Student</v-btn>
            </template>
            <v-card>
              <v-card-title>Confirm Return to Student</v-card-title>
              <v-card-text>Are you sure you want to return this request to the student? This will send the request back to the student and will require the student to submit the form again before the Records office can process it.</v-card-text>
              <v-card-actions>
                <v-btn text @click="returnDialog = false">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="info" @click="returnToStudent()">Return to Student</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-col>
      <v-col cols="12" md="10" offset-md="1" lg="6" offset-lg="0">
        <v-card class="mt-2">
          <v-card-text>
            <v-textarea v-model="comment" label="Add a message" outlined :rows="comment.length < 100 ? 3 : 5" hide-details></v-textarea>
            <v-checkbox v-model="invisibleToStudent" label="Hide message from student"></v-checkbox>
            <v-btn :disabled="comment === ''" @click="addComment">Add Message</v-btn>
          </v-card-text>
        </v-card>
        <v-subheader>
          Request Timeline
          <v-spacer></v-spacer>
          <v-btn small @click="changeSort()">
            {{ sortOldestFirst ? 'Oldest First' : 'Newest First' }}
            <v-icon right>{{ sortOldestFirst ? 'fal fa-sort-amount-down' : 'fal fa-sort-amount-up-alt' }}</v-icon>
          </v-btn>
        </v-subheader>
        <v-timeline v-if="timeline.length > 0" dense class="mini-scrollbar">
          <v-timeline-item
            v-for="(message, index) in timeline"
            :key="'message-'+index"
            :color="message.color || 'info'"
            :icon="message.icon || 'fal fa-comment'"
          >
            <v-card>
              <v-card-text ref="message.textRef" v-html="messageText(message)"></v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
      <v-dialog v-model="gradeDialog" width="300" persistent>
        <v-card>
          <v-card-title>Assign Grade</v-card-title>
          <v-card-text>
            <p>Because of the point we have reached in the semester, you need to specify whether this student should receive a W or F when dropping this course.</p>
            <v-select v-model="gradeSelection" :items="gradeOptions" label="Grade"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="gradeDialog = false">Cancel</v-btn>
            <v-btn text color="success" :disabled="!gradeSelection" @click="assignGrade()">Assign Grade</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<style>
.v-timeline-item .v-timeline-item__inner-dot i.v-icon {
  font-size: 18px;
}
.v-timeline-item.reverse-icon .v-timeline-item__inner-dot i.v-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
import { buildCourseBlock, addToCalendar } from '@/components/student/schedule-change/functions'

export default {
  components: {
    CourseEntry: () => import('@/components/student/schedule-change/StudentCourseEntry'),
    UserSearch: () => import('@/components/greatscots/searchField')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const isRecords = computed(() => 'Records' in roles.value || 'Technology Services' in roles.value)
    const isAdvisor = ref(false)
    const id = computed(() => root.$route.params.id)
    const status = ref('')

    const viewSchedule = ref(false)
    const schedule = ref([])
    const classWeek = ref([])
    let dt = new Date()
    dt.setDate(dt.getDate() - dt.getDay())
    const calStart = ref(dt.toISOString().substr(0, 10))

    const termCode = ref('')
    const stuPidm = ref('')
    const stuName = ref('')
    const stuBannerId = ref('')
    const stuEmail = ref('')
    const changes = ref([])

    const sortOldestFirst = ref(false)
    const timeline = ref([])
    const locked = ref(false)
    const comment = ref('')
    const invisibleToStudent = ref(false)

    const service = root.$feathers.service('student/schedule-change')

    const requireGrade = ref(false)
    const gradeDialog = ref(false)
    const gradeSelection = ref(null)
    const gradeOptions = ref(['W', 'F'])
    const gradeItem = ref({})
    const gradeIndex = ref(null)

    function addComment () {
      if (comment.value !== '') {
        let obj = {
          pidm: user.value.pidm,
          name: user.value.name,
          date: new Date(),
          text: comment.value,
          icon: 'fal fa-comment fa-flip-horizontal',
          color: 'success',
          visibleToStudent: !invisibleToStudent.value
        }
        service.patch(id.value, { $push: { timeline: obj } }).then(afterLoad).then(() => {
          comment.value = ''
        })
      }
    }

    async function approveCourse (item, index, sendEmail) {
      if (requireGrade.value && item.action === 'drop') {
        gradeItem.value = item
        gradeDialog.value = true
        gradeIndex.value = index
        return
      }
      try {
        let obj = {
          pidm: user.value.pidm,
          name: user.value.name,
          date: new Date(),
          text: 'Course Change ' + (item.action === 'add' ? 'Approved' : 'Acknowledged') + ': ' + item.title,
          icon: 'fal fa-check',
          color: 'success',
          visibleToStudent: true
        }
        const patch = {
          $push: { timeline: obj },
          'changes.$[elem].status': 'approved',
          'changes.$[elem].approval.date': new Date()
        }
        const arrayFilters = [{ 'elem.status': item.status, 'elem.crn': item.crn, 'elem.action': item.action }]
        const data = await service.patch(id.value, patch, { query: { arrayFilters } })
        afterLoad(data)
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error adding approval: ' + e })
      }
      const isApproved = await checkAllApproved()
      if (!isApproved) {
        if (sendEmail || sendEmail == null) {
          try {
            console.log('Would be sending email')
            // Send an email to the Records office to notify them of the submission
            await root.$feathers.service('system/email').create({
              to: 'recordsoffice@covenant.edu',
              from: { email: 'report-email@covenant.edu', name: 'Schedule Change' },
              subject: 'Student schedule course approved',
              html: user.value.preferred + ' ' + user.value.last + ' has approved a schedule change for ' + stuName.value + '. Please click on the link below to access the Schedule Change system to review this request.<br/><br/><a href="https://portal.covenant.edu/student/schedule-change/' + id.value + '">https://portal.covenant.edu/student/schedule-change/' + id.value + '</a>'
            })
          } catch (e) {
            root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error sending email to Records office: ' + e })
          }
        }
      }
    }

    async function assignGrade () {
      if (gradeSelection.value) {
        let obj = {
          pidm: user.value.pidm,
          name: user.value.name,
          date: new Date(),
          text: 'Course Change Acknowledged: ' + gradeItem.value.title,
          icon: 'fal fa-check',
          color: 'success',
          visibleToStudent: true
        }
        try {
          const data = await service.patch(id.value, {
            $push: { timeline: obj },
            ['changes.' + gradeIndex.value + '.status']: 'approved',
            ['changes.' + gradeIndex.value + '.approval.date']: new Date(),
            ['changes.' + gradeIndex.value + '.approval.grade']: gradeSelection.value
          })
          afterLoad(data)
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error assigning grade: ' + e })
        }
        try {
          // Send an email to the Records office to notify them of the submission
          await root.$feathers.service('system/email').create({
            to: 'recordsoffice@covenant.edu',
            from: { email: 'report-email@covenant.edu', name: 'Schedule Change' },
            subject: 'Student schedule course drop approved; grade assigned',
            html: user.value.preferred + ' ' + user.value.last + ' has completed a schedule change for ' + stuName.value + ' and assigned a grade. Please click on the link below to access the Schedule Change system to review this request.<br/><br/><a href="https://portal.covenant.edu/student/schedule-change/' + id.value + '">https://portal.covenant.edu/student/schedule-change/' + id.value + '</a>'
          })
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error sending email to Records office: ' + e })
        }
        await checkAllApproved()
      }
      gradeSelection.value = null
      gradeDialog.value = false
    }

    function checkTextWidth (item) {
      setTimeout(() => { console.log(item) }, 1000)
    }

    onMounted(() => {
      // Load the schedule change
      service.get(id.value).then(async (data) => {
        let { term } = data
        afterLoad(data)

        let { data: termData } = await root.$feathers.service('system/term').find({ query: { term } })
        termData = termData[0]
        if ('scheduleChangeMessages' in termData && termData.scheduleChangeMessages.length > 0) {
          for (let i = 0; i < termData.scheduleChangeMessages.length; i++) {
            const { effDate, grade } = termData.scheduleChangeMessages[i]
            if (new Date(effDate) < new Date()) {
              requireGrade.value = grade
            }
          }
        } else {
          let start = new Date(termData.start)
          let end = new Date(termData.end)
          let now = new Date()
          let daysIntoTerm = Math.floor((now - start) / 86400000)
          let daysRemainingInTerm = Math.floor((end - now) / 86400000)
          let termLength = Math.floor((end - start) / 86400000)
          let percentageOfTermPassed = daysIntoTerm / termLength * 100

          if (percentageOfTermPassed > 60 && daysRemainingInTerm <= 21) {
            // Require an instructor for any dropped course within this time period to determine whether it should be a W or F.
            requireGrade.value = true
          }
          // For testing purposes
          requireGrade.value = true
        }
      })
      service.on('patched', (data) => {
        if (data._id === id.value) {
          afterLoad(data)
        }
      })
    })

    const advisors = ref([])
    const instructorsApproved = ref(true)
    const advisorApproved = ref(false)
    const recordsApproved = ref(false)

    async function afterLoad ({ term, pidm, bannerId, name, status: procStatus, changes: stuChanges, advisorApproval, recordsApproval, timeline: stuTimeline }) {
      termCode.value = term
      stuPidm.value = pidm
      stuBannerId.value = bannerId
      stuName.value = name
      stuEmail.value = ''
      root.$feathers.service('directory/people').find({ query: { pidm } }).then(({ data }) => {
        if (data.length > 0) {
          stuEmail.value = data[0].email
        }
      })
      status.value = procStatus
      let userPidm = user.value.pidm
      // Make sure that the logged-in user is the one connected with this entry either as an approver of a schedule change or an advisor
      let isValid = false
      if (!(Array.isArray(advisorApproval))) {
        advisorApproval = [advisorApproval]
      }

      // If all entries in the advisorApproval array have a date, then all advisors have approved
      advisorApproved.value = advisorApproval.filter(({ date }) => date == null).length === 0
      // Build a hash of the advisor approval dates so we can easily grab these when mapping the directory information to build the advisors object
      const hash = {}
      advisorApproval.forEach(({ pidm, date }) => { hash[pidm] = date ? stringFormatDate(date) : null })
      // Load the advisor information from the directory
      const { data: advData } = await root.$feathers.service('directory/people').find({ query: { pidm: { $in: advisorApproval.map(({ pidm }) => pidm) } } })
      // Map the directory data (including the date from the hash above) into the advisors array so we can display it
      advisors.value = advData.map(({ name: { last, first }, email, pidm }) => { return { pidm, date: hash[pidm], name: last + ', ' + first, email } })
      // Check to see if the current user is an advisor
      if (advisorApproval.filter(({ pidm }) => pidm === user.value.pidm).length > 0) {
        isAdvisor.value = true
        isValid = true
      }

      if (typeof (recordsApproval) === 'object') {
        if ('date' in recordsApproval && recordsApproval.date != null) recordsApproved.value = true
      }

      stuChanges.forEach(async ({ approval, crn, action }) => {
        if (approval && typeof (approval) === 'object') {
          if (approval.pidm === userPidm) isValid = true
          if (!('date' in approval) && approval.date == null) instructorsApproved.value = false
        }
      })
      if (!isValid && !isRecords.value) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'You are not assigned as an approver for that request.' })
        root.$router.push('/student/schedule-change/admin')
        return
      }
      // Load the student's current schedule
      schedule.value = []
      let { data } = await root.$feathers.service('student/term-detail').find({ query: { term, pidm } })
      if (data.length > 0) {
        // If the advisor data is already loaded, don't override it; this is just loading as a default if it isn't in the record already (but it should be if the record was submitted)
        data[0].schedule.forEach(({ title, crn, meetingBase }) => {
          let obj = { title, crn, meetingBase, removed: false, added: false }
          schedule.value.push(obj)
          addToCalendar(obj, true, calStart.value, classWeek.value)
        })
      }

      // Reset the change array to be empty, then get the class information associated with each of the submitted changes
      changes.value = []
      for (const { approval, crn, action, status } of stuChanges) {
        let { data: classData } = await root.$feathers.service('calendar/classes').find({ query: { term, crn, $populate: 'instructors.directoryId' } })
        if (classData.length > 0) {
          let obj = { ...buildCourseBlock({ ...classData[0] }), action, status, approval }
          changes.value.push(obj)
          if (action === 'add') {
            addToCalendar(obj, false, calStart.value, classWeek.value)
          } else {
            classWeek.value.forEach((row) => {
              if (row.crn === obj.crn) {
                row.removed = true
              }
            })
          }
        }
      }
      // Clear the timeline, then populate the timeline adding in an "expanded" option to what is saved
      timeline.value = []
      // stuTimeline.reverse()
      stuTimeline.forEach((comment) => timeline.value.push({ ...comment, expanded: false, textRef: null }))
      if (!sortOldestFirst.value) timeline.value.reverse()

      if (recordsApproval && typeof (recordsApproval) === 'object' && 'date' in recordsApproval && recordsApproval.date != null) {
        locked.value = true
      }

      const isApproved = await checkAllApproved(!isRecords.value)
      if (isApproved && status.value === 'pending') {
        status.value = 'approved'
      }
    }

    function changeSort () {
      sortOldestFirst.value = !sortOldestFirst.value
      timeline.value.reverse()
    }

    async function advisorApprove () {
      try {
        // Set the advisorApproval pidm to the current user and the current date
        // Determine the appropriate index for the current advisor
        for (let i = 0; i < advisors.value.length; i++) {
          if (advisors.value[i].pidm === user.value.pidm) {
            const dt = new Date()
            const temp = await root.$feathers.service('student/schedule-change').patch(id.value, {
              'advisorApproval.$.date': dt,
              $push: { timeline: { pidm: user.value.pidm, name: user.value.name, date: dt, text: 'Advisor Approved', icon: 'fal fa-check', color: 'success', visibleToStudent: true } }
            }, { query: {
              _id: id.value,
              'advisorApproval.pidm': user.value.pidm
            } })
            afterLoad(temp)
          }
        }
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error saving advisor approval: ' + e })
      }
      if (!requireGrade.value) {
        await instructorApproveAll(false)
      }
      const isApproved = await checkAllApproved()
      if (!isApproved) {
        try {
          // Send an email to the Records office to notify them of the submission
          await root.$feathers.service('system/email').create({
            to: 'recordsoffice@covenant.edu',
            from: { email: 'report-email@covenant.edu', name: 'Schedule Change' },
            subject: 'Student schedule advisor approved',
            html: user.value.preferred + ' ' + user.value.last + ' has approved a schedule change for ' + stuName.value + '. Please click on the link below to access the Schedule Change system to review this request.<br/><br/><a href="https://portal.covenant.edu/student/schedule-change/' + id.value + '">https://portal.covenant.edu/student/schedule-change/' + id.value + '</a>'
          })
          root.$store.dispatch('main/snackbar', { color: 'success', text: 'Schedule changes approved.' })
          root.$router.push('/student/schedule-change/admin')
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error sending email to Records office: ' + e })
        }
      }
    }

    async function instructorApproveAll (sendEmail) {
      for (let i = 0; i < changes.value.length; i++) {
        if (changes.value[i].approval.pidm === user.value.pidm && changes.value[i].approval.status !== 'approved') {
          await approveCourse(changes.value[i], i, false)
        }
      }
      if (sendEmail == null) {
        const isApproved = await checkAllApproved()
        if (!isApproved) {
          try {
            await root.$feathers.service('system/email').create({
              to: 'recordsoffice@covenant.edu',
              from: { email: 'report-email@covenant.edu', name: 'Schedule Change' },
              subject: 'Student schedule advisor approved',
              html: user.value.preferred + ' ' + user.value.last + ' has approved a schedule change for ' + stuName.value + '. Please click on the link below to access the Schedule Change system to review this request.<br/><br/><a href="https://portal.covenant.edu/student/schedule-change/' + id.value + '">https://portal.covenant.edu/student/schedule-change/' + id.value + '</a>'
            })
            root.$store.dispatch('main/snackbar', { color: 'success', text: 'Schedule changes approved.' })
            root.$router.push('/student/schedule-change/admin')
          } catch (e) {
            root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error sending email to Records office: ' + e })
          }
        }
      }
    }

    async function markProcessed () {
      let pidm = user.value.pidm
      // Set the recordsApproval pidm to the current user and the current date
      let obj = {
        'recordsApproval.pidm': pidm,
        'recordsApproval.date': new Date(),
        status: 'processed'
      }
      // This will override any needed instructor or advisor approvals and mark them all as completed
      if (!advisorApproved.value) {
        for (let i = 0; i < advisors.value.length; i++) {
          if (!('date' in advisors.value[i])) {
            obj['advisorApproval.' + i] = { pidm, date: new Date() }
          }
        }
      }
      if (!instructorsApproved.value) {
        changes.value.forEach((row, index) => {
          obj['changes.' + index + '.status'] = 'processed'
          if ('approval' in row && (!('date' in row.approval) || row.approval.date == null)) {
            obj['changes.' + index + '.approval.pidm'] = pidm
            obj['changes.' + index + '.approval.date'] = new Date()
          }
        })
      }
      try {
        await root.$feathers.service('student/schedule-change').patch(id.value, obj)
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Schedule changes marked as processed.' })
        root.$router.push('/student/schedule-change/admin')
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error marking as processed: ' + e })
      }
    }

    const returnDialog = ref(false)
    async function returnToStudent () {
      // Remove the lock attribute from all of the submitted changes and notify the student
      let obj = {
        status: 'returned',
        $push: {
          timeline: {
            pidm: user.value.pidm,
            name: user.value.name,
            date: new Date(),
            text: 'Submission returned to student',
            icon: 'fal fa-arrow-left',
            color: 'error',
            visibleToStudent: true
          }
        }
      }
      changes.value.forEach((row, index) => {
        obj['changes.' + index + '.status'] = 'returned'
      })
      try {
        await root.$feathers.service('student/schedule-change').patch(id.value, obj)
        await root.$feathers.service('system/email').create({
          to: stuEmail.value,
          from: { email: 'report-email@covenant.edu', name: 'Schedule Change' },
          subject: 'Your schedule change has been returned',
          html: 'Your schedule change has been returned to you. Please log into the <a href="https://portal.covenant.edu/student/schedule-change/form">Schedule Change Form</a> to view the schedule change and see what may need to be fixed before you can submit it again.'
        })
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Schedule change request returned to student.' })
        root.$router.push('/student/schedule-change/admin')
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error returning to student: ' + e })
      }
    }

    function messageText (message) {
      return message.text + '<div style="font-size:.8em;opacity:.7;padding-top:0">' + message.name + ' -- ' + stringFormatDate(message.date) + '</div>'
    }

    const advisorChangeDialog = ref(false)
    const newAdvisor = ref({})
    const removeApproval = ref(false)
    const emailNewAdvisor = ref(true)
    async function changeAdvisor (index, oldPidm) {
      try {
        const { pidm, name, email } = await root.$feathers.service('directory/people').get(newAdvisor.value.value)
        let obj = {
          ['advisorApproval.' + index]: { pidm },
          $push: {
            timeline: {
              pidm: user.value.pidm,
              name: user.value.name,
              date: new Date(),
              text: 'Advisor changed from ' + advisors.value[index].name + ' to ' + name.last + ', ' + name.first,
              icon: 'fal fa-exchange',
              color: 'info',
              visibleToStudent: true
            }
          }
        }
        const scData = await root.$feathers.service('student/schedule-change').patch(id.value, obj)
        if (emailNewAdvisor.value) {
          // Send an email to the student's advisor
          await root.$feathers.service('system/email').create({
            to: email,
            from: { email: 'report-email@covenant.edu', name: 'Schedule Change' },
            subject: 'Student schedule change requires your approval',
            html: 'A schedule change for ' + stuName.value + ' was just updated by the Records Office to set you as their advisor. Please click on the link below to access the Schedule Change system to approve this request.<br/><br/><a href="https://portal.covenant.edu/student/schedule-change">https://portal.covenant.edu/student/schedule-change</a>'
          })
        }

        const { data } = await root.$feathers.service('student/term-detail').find({ query: { term: scData.term, pidm: scData.pidm } })
        if (data.length > 0) {
          await root.$feathers.service('student/term-detail').patch(data[0]._id, {
            'academics.advisor': [{
              pidm,
              name: name.last + ', ' + name.first,
              email
            }]
          })
        }
        advisors.value.splice(index, 1, { date: 'No', ...obj.advisorApproval, name: name.last + ', ' + name.first, email })
        advisorChangeDialog.value = false
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Advisor changed successfully.' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error changing advisor: ' + e })
      }
    }

    const showAllChanges = ref(false)

    async function checkAllApproved (dontSendEmail) {
      if (status.value !== 'pending') return false
      if (changes.value.filter(({ status: changeStatus }) => changeStatus !== 'approved' && changeStatus !== 'processed').length === 0 && advisorApproved.value) {
        await root.$feathers.service('student/schedule-change').patch(id.value, { status: 'approved' })
        if (!dontSendEmail) {
          await root.$feathers.service('system/email').create({
            to: 'recordsoffice@covenant.edu',
            from: { email: 'report-email@covenant.edu', name: 'Schedule Change' },
            subject: 'Student schedule change final approval',
            html: 'A schedule change for ' + stuName.value + ' was just granted the final approval and is ready to be processed.'
          })
        }
        return true
      }
      return false
    }

    return {
      user,
      roles,
      isRecords,
      isAdvisor,
      id,
      status,
      viewSchedule,
      schedule,
      classWeek,
      calStart,
      termCode,
      stuPidm,
      stuBannerId,
      stuName,
      changes,
      timeline,
      sortOldestFirst,
      stringFormatDate,
      buildCourseBlock,
      addToCalendar,
      locked,
      comment,
      invisibleToStudent,
      requireGrade,
      gradeDialog,
      gradeSelection,
      gradeOptions,
      addComment,
      approveCourse,
      assignGrade,
      checkTextWidth,
      advisors,
      instructorsApproved,
      advisorApproved,
      recordsApproved,
      changeSort,
      advisorApprove,
      instructorApproveAll,
      markProcessed,
      returnDialog,
      returnToStudent,
      messageText,
      advisorChangeDialog,
      newAdvisor,
      removeApproval,
      emailNewAdvisor,
      changeAdvisor,
      showAllChanges
    }
  }
}
</script>
